html,
body {
  border: none;
  margin: 0;
  padding: 0;
}

html, body, div#root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
address,
big,
cite,
code,
em,
font,
img,
small,
strike,
sub,
sup,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tr,
th,
td {
  border: none;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
}

blockquote::before,
blockquote::after {
  content: "";
}

html {
    font-size: 11px;
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: proxima-nova, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  word-wrap: break-word;
}

@font-face {
    font-family: 'AltrinchamW01';
    src: url('./fonts/AltrinchamW01-Regular.eot');
    src: url('./fonts/AltrinchamW01-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/AltrinchamW01-Regular.woff2') format('woff2'),
    url('./fonts/AltrinchamW01-Regular.woff') format('woff'),
    url('./fonts/AltrinchamW01-Regular.ttf') format('truetype'),
    url('./fonts/AltrinchamW01-Regular.svg#AltrinchamW01-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.small, small {
    font-size: .9rem;
}

h1, h2, h3 {
  font-family: 'AltrinchamW01';
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 500;
}

a {
  color: inherit;
}

img {
  width: 100%;
}

body.modal-active {
  overflow: hidden;
}

.formElementDropdown:after {
  content: '';
  background-image: url('./assets/DropDownArrow.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 6px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.formElementDropdown.taupe:after {
  background-image: url('./assets/DropDownArrowTaupe.svg');
}

[data-reach-accordion-item] [data-reach-accordion-button]:after {
  content: '';
  background-image: url('./assets/AccordionPlus.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

[data-reach-accordion-item][data-state="open"] [data-reach-accordion-button]:after {
  transform: translateY(-50%) rotate(45deg);
}

.sr-only{position:absolute;width:1px;height:1px;padding:0;overflow:hidden;clip:rect(0,0,0,0);white-space:nowrap;border:0}